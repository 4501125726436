import axios from "axios";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { ToastOnFailure, ToastOnSuccess } from "../../Toast/ToastMsg";

interface IProps {
  show?: boolean;
  data?: any;
  setShow?: any;
  flagUpdated?:any;
  setFlagUpdated?:any;
}
const EditEpigenticDetailsModel: React.FC<IProps> = ({ show, setShow, data,flagUpdated ,setFlagUpdated}) => {
  const [age, setAge] = useState(data.age);
  const onSubmit = () => {
    if (age) {
      setShow(false);
      updateEpigenticAgeDetails();
    } else {
      ToastOnFailure("Please enter Epigenetic Age  ");
    }
  };

   /**** update user's age details  */

   const updateEpigenticAgeDetails = () => {
    axios
      .patch(
        "https://stage.api.hupe.life/admin/updateScoreCard",
        {
          userId: data.userId,
          age:Number(age)
        },
        {
          headers: {
            Authorization: sessionStorage.getItem("AUTH_TOKEN") || "",
          },
        }
      )
      .then((response: any) => {
        ToastOnSuccess(response.data.message);
        setShow(false);
        setFlagUpdated(!flagUpdated)
        
      })
      .catch((error) => {
        console.log(error);
        ToastOnFailure(error.response.data.message);
      });
  };
  useEffect(()=>{
    setAge(data.age)
  },[data.age,show])
  return (
    <>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Details </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>{data?.name}</Form.Label>
              <Form.Control
                type="email"
                value={`${age}`}
                autoFocus
                onChange={(e) => setAge(e.target.value)}
              />
            </Form.Group>
            <Button
              className="text-center"
              variant="success"
              onClick={() => onSubmit()}
            >
              Update Details
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditEpigenticDetailsModel;
