import "./admin.style.scss";
import { useEffect, useState } from "react";
import ProgressBar from "../../component/ProgressBar/ProgressBar";
import LineChart from "../../component/LineChart/LineChart";
import { Modal } from "react-bootstrap";
import axios from "axios";
import Spinner from "../../shared/Spinner/Spinner";
import AdminEdit from "../../assets/img/admin_edit.png";
import EditEpigenticDetailsModel from "../../shared/models/edit-details/EditDetailsModel";
import EmptyDataSign from "../../assets/img/empty-data.svg";
import Factor from "../../shared/factors/Factor";
import { formatDate } from "../../util/util";

interface IProps {
  userId: any;
}

const AdminPanel = ({ userId }: IProps) => {
  const [graph, setGraph] = useState<any>([]);
  const [graphName, setGraphName] = useState<any>("cardiovascularSystem");
  const [showValue, setShowValue] = useState(false);
  const [dashboardResult, setDashBoardResult] = useState();
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [flagUpdated, setFlagUpdated] = useState(false);
  const [labels, setLabels] = useState<any>([]);
  const [scoreCardAllData, setScoreCardAllData] = useState<any>();
  const [epigenticAge, setEpigenticAge] = useState<any>();
  const [showFactorEdit, setShowFactorEdit] = useState(false);
  const [factorData,setFactorData] = useState<any>([])
  const [scoreCardId,setScoreCardId] = useState('');
  const [redirectionFactor, setRedirectionFactor] = useState("");
  const [data, setData] = useState<any>([]);

  useEffect(()=>{
    if (redirectionFactor == "Positive") {
      setData([factorData[0]?.scoreCardpositveFactor]);
    } else if (redirectionFactor == "Negative"){
      setData([factorData[0]?.scoreCardnegeitveFactor]);
    }
  },[redirectionFactor])
    
  const getDashboardDetails = () => {
    if (userId) {
      setLoading(true);
      axios
        .get(`https://stage.api.hupe.life/scoreCard?userId=${userId}`, {
          headers: {
            Authorization: sessionStorage.getItem("AUTH_TOKEN") || "",
          },
        })
        .then((response: any) => {
          setLoading(false);
          setDashBoardResult(response.data.data);
          getPositiveAndNegativeFactor(userId,response.data.data.id);
          setScoreCardId(response.data.data.id)
          DobToAgeConverter(response.data.data.dateOfBirth);
          getScoreCardAllDetails();
        })
        .catch((error: any) => {
          console.log(error);
          setLoading(false);
        });
    }
    setLoading(false);
  };

  //  -------------------------------Positive and negative Factor------------------------------------------------

  const factors = (graphName: string) => {
    setGraphName(graphName);
    setGraph(scoreCardAllData[graphName].value);
    setLabels(scoreCardAllData[graphName].date);
    if (window.innerWidth < 1023) {
      setShowValue(true);
    }
  };

  const modifyGraphName = (name: string) => {
    switch (name) {
      case "cardiovascularSystem":
        return `Cardiovascular System`;
      case `hormonesMetabolism`:
        return `Hormones & Metabolism`;
      case `respiratorySystem`:
        return `Respiratory System`;
      case `epigeneticAge`:
        return `Epigenetic Age`;
      case `hupeHealthScore`:
        return `Hupe Health Score`;
      case `energyMitochondria`:
        return `Energy / Mitochondrial`;
      case `kidney`:
        return `Kidney`;
      case `longevityInflammation`:
        return `Longevity / Inflammation`;
    }
  };

  /** method for stop existing event bubbling **/
  const handleShowModel = (e?: any) => {
    e.stopPropagation();
    setShow(true);
  };

  const scoreCardGraphHandler = (scoreCardData: any) => {
    const graphFinalData = {
      cardiovascularSystem: {
        date: [],
        value: [],
      },
      energyMitochondria: {
        date: [],
        value: [],
      },
      epigeneticAge: {
        date: [],
        value: [],
      },
      hormonesMetabolism: {
        date: [],
        value: [],
      },
      hupeHealthScore: {
        date: [],
        value: [],
      },
      kidney: {
        date: [],
        value: [],
      },
      longevityInflammation: {
        date: [],
        value: [],
      },
      respiratorySystem: {
        date: [],
        value: [],
      },
    };


    for(let i=0; i < scoreCardData.length; i++) {
      const cardio: any = graphFinalData["cardiovascularSystem"];
      cardio.date.push(formatDate(scoreCardData[i].reportDate));
      cardio.value.push(scoreCardData[i].cardiovascularSystem);

      const energy: any = graphFinalData["energyMitochondria"];
      energy.date.push(formatDate(scoreCardData[i].reportDate));
      energy.value.push(scoreCardData[i].energyMitochondria);

      const epic: any = graphFinalData["epigeneticAge"];
      epic.date.push(formatDate(scoreCardData[i].reportDate));
      epic.value.push(scoreCardData[i].epigeneticAge);

      const hormones: any = graphFinalData["hormonesMetabolism"];
      hormones.date.push(formatDate(scoreCardData[i].reportDate));
      hormones.value.push(scoreCardData[i].hormonesMetabolism);

      const health: any = graphFinalData["hupeHealthScore"];
      health.date.push(formatDate(scoreCardData[i].reportDate));
      health.value.push(scoreCardData[i].hupeHealthScore);

      const kidney: any = graphFinalData["kidney"];
      kidney.date.push(formatDate(scoreCardData[i].reportDate));
      kidney.value.push(scoreCardData[i].kidney);

      const longevity: any = graphFinalData["longevityInflammation"];
      longevity.date.push(formatDate(scoreCardData[i].reportDate));
      longevity.value.push(scoreCardData[i].longevityInflammation);

      const respiratory: any = graphFinalData["respiratorySystem"];
      respiratory.date.push(formatDate(scoreCardData[i].reportDate));
      respiratory.value.push(scoreCardData[i].respiratorySystem);

    }

    return graphFinalData;
  };

  const getScoreCardAllDetails = () => {
    setLoading(true);
    if (userId) {
      axios
        .get(`https://stage.api.hupe.life/scoreCardAll?userId=${userId}`, {
          headers: {
            Authorization: sessionStorage.getItem("AUTH_TOKEN") || "",
          },
        })
        .then((response: any) => {
          setLoading(false);
          const scoreDataArray: any = scoreCardGraphHandler(response.data.data);
          setScoreCardAllData(scoreDataArray);
          setGraphName("cardiovascularSystem");
          setGraph(scoreDataArray["cardiovascularSystem"].value);
          setLabels(scoreDataArray["cardiovascularSystem"].date);
        })
        .catch((err: any) => {
          setLoading(false);
          console.log(err);
        });
    }
  };

  /** factor api */
  const getPositiveAndNegativeFactor = (
    userIdParmas: any,
    scoreCardIdParmas: any
  ) => {
    setLoading(true);
    if (userId) {
      axios
        .get(
          `https://stage.api.hupe.life/get/scoreCardFactor?userId=${userIdParmas}&scoreCardId=${scoreCardIdParmas}`,
          {
            headers: {
              Authorization: sessionStorage.getItem("AUTH_TOKEN") || "",
            },
          }
        )
        .then((response: any) => {
          setLoading(false);
          setFactorData([response.data.data])
        })
        .catch((err: any) => {
          setLoading(false);
          console.log(err);
        });
    }
  };

  const DobToAgeConverter = (dateString: any) => {
    let today = new Date();
    let birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    setEpigenticAge(age);
    return age;
  };


  useEffect(() => {
    getDashboardDetails();
  }, [userId, flagUpdated]);

  return (
    <>
      <div className="MainPannel admin-panal spaceN1 AdminAdjust">
        {loading ? (
          <Spinner />
        ) : (
          <div className="container-fluid">
            {dashboardResult && !loading ? (
              <div className="row">
                <div className="col-md-12 col-xxl-8 col-lg-7">
                  <div className="serc">
                    <div className="row d-lg-block d-none">
                      <div className="col-md-12">
                        <h3 className="heading3">Dashboard</h3>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4 col-4 progressCol">
                        <div
                          className={`${
                            graphName == "cardiovascularSystem"
                              ? `component-section sechover secActive`
                              : `component-section sechover `
                          } `}
                        >
                          <div
                            className="imgDiv progressBar progressBar"
                            onClick={() => factors("cardiovascularSystem")}
                          >
                            <ProgressBar
                              percentage={
                                dashboardResult
                                  ? dashboardResult["cardiovascularSystem"]
                                  : 0
                              }
                              size={"45"}
                            />
                          </div>
                          <h6 className="progressbar-component-heading ">
                            Cardiovascular System
                          </h6>
                        </div>
                      </div>

                      <div className="col-md-4 col-4 progressCol">
                        <div
                          className={`${
                            graphName == "hormonesMetabolism"
                              ? `component-section sechover secActive`
                              : `component-section sechover`
                          } `}
                        >
                          <div
                            className="imgDiv progressBar"
                            onClick={() => factors("hormonesMetabolism")}
                          >
                            <ProgressBar
                              percentage={
                                dashboardResult
                                  ? dashboardResult["hormonesMetabolism"]
                                  : 0
                              }
                              size={"45"}
                            />
                          </div>
                          <h6 className="progressbar-component-heading ">
                            Hormones & Metabolism
                          </h6>
                        </div>
                      </div>

                      <div className="col-md-4 col-4 progressCol">
                        <div
                          className={`${
                            graphName == "respiratorySystem"
                              ? `component-section sechover secActive`
                              : `component-section sechover`
                          } `}
                        >
                          <div
                            className="imgDiv progressBar"
                            onClick={() => factors("respiratorySystem")}
                          >
                            <ProgressBar
                              percentage={
                                dashboardResult
                                  ? dashboardResult["respiratorySystem"]
                                  : 0
                              }
                              size={"45"}
                            />
                          </div>
                          <h6 className="progressbar-component-heading">
                            Respiratory System
                          </h6>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="offset-md-1 col-md-6 col-6 ProgressBigSec">
                        <div
                          className={`${
                            graphName == "epigeneticAge"
                              ? `component-section component-section1 secActive`
                              : `component-section component-section1 sechover`
                          } `}
                        >
                          <div
                            className="imgDiv progressBar"
                            onClick={() => factors("epigeneticAge")}
                          >
                            <img
                              className="edit-icons"
                              src={AdminEdit}
                              alt="edit btn"
                              onClick={(e: any) => handleShowModel(e)}
                            />
                            <ProgressBar
                              percentage={
                                dashboardResult
                                  ? dashboardResult["epigeneticAge"]
                                  : 0
                              }
                              size={"45"}
                              checkAge={
                                dashboardResult &&
                                dashboardResult["epigeneticAge"] <= epigenticAge
                                  ? "#27AE60"
                                  : "#EB5757"
                              }
                              fontsize={"3vw"}
                              ringsize={"11.9vw"}
                              isLongBar={true}
                            />
                          </div>
                          <h5 className="progressbar-component-heading ">
                            Epigenetic Age
                          </h5>
                        </div>
                      </div>

                      <div className="col-md-6 offset-md-right-1 col-6 ProgressBigSec">
                        <div
                          className={`${
                            graphName == "hupeHealthScore"
                              ? `component-section component-section1 sechover secActive`
                              : `component-section component-section1 sechover`
                          } `}
                        >
                          <div
                            className="imgDiv progressBar"
                            onClick={() => factors("hupeHealthScore")}
                          >
                            <ProgressBar
                              percentage={
                                dashboardResult
                                  ? Math.round(
                                      dashboardResult["hupeHealthScore"]
                                    )
                                  : 0
                              }
                              size={"45"}
                              fontsize={"3vw"}
                              ringsize={"11.9vw"}
                              // isResult={true}
                              // average={averageData}
                              isLongBar={true}
                            />
                          </div>
                          <h5 className="progressbar-component-heading ">
                            Hupe Health Score
                          </h5>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-4 col-4 progressCol">
                        <div
                          className={`${
                            graphName == "energyMitochondria"
                              ? `component-section sechover secActive`
                              : `component-section sechover`
                          } `}
                        >
                          <div
                            className="imgDiv progressBar"
                            onClick={() => factors("energyMitochondria")}
                          >
                            <ProgressBar
                              percentage={
                                dashboardResult
                                  ? Math.round(
                                      dashboardResult["energyMitochondria"]
                                    )
                                  : 0
                              }
                              size={"45"}
                            />
                          </div>
                          <h6 className="progressbar-component-heading ">
                            Energy / Mitochondrial
                          </h6>
                        </div>
                      </div>
                      <div className="col-md-4 col-4 progressCol">
                        <div
                          className={`${
                            graphName == "kidney"
                              ? `component-section sechover secActive`
                              : `component-section sechover`
                          } `}
                        >
                          <div
                            className="imgDiv progressBar"
                            onClick={() => factors("kidney")}
                          >
                            <ProgressBar
                              percentage={
                                dashboardResult
                                  ? Math.round(dashboardResult["kidney"])
                                  : 0
                              }
                              size={"45"}
                            />
                          </div>
                          <h6 className="progressbar-component-heading ">
                            Kidney
                          </h6>
                        </div>
                      </div>
                      <div className="col-md-4 col-4 progressCol">
                        <div
                          className={`${
                            graphName == "longevityInflammation"
                              ? `component-section sechover secActive`
                              : `component-section sechover`
                          } `}
                        >
                          <div
                            className="imgDiv progressBar"
                            onClick={() => factors("longevityInflammation")}
                          >
                            <ProgressBar
                              percentage={
                                dashboardResult
                                  ? Math.round(
                                      dashboardResult["longevityInflammation"]
                                    )
                                  : 0
                              }
                              size={"45"}
                            />
                          </div>
                          <h6 className="progressbar-component-heading ">
                            Longevity / Inflammation
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* -----------------------------------Cardiovascular System-------------------------------------------------------- */}

                <div
                  className={`col-md-12 col-xxl-4 col-lg-5 kpi-graph-shared`}
                >
                  <div className="admininner-sec serc AdminBorder">
                    <div className="sec-hover sec0">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="sectr">
                            <h5 className="graphHeading">
                              {modifyGraphName(graphName)}
                            </h5>
                          </div>
                        </div>
                      </div>
                      <div className="row factorSec ">
                        <div className="col-md-12">
                          <div className="imgDiv1">
                            {scoreCardAllData && labels && graph ? (
                              <LineChart data={graph} labels={labels} />
                            ) : (
                              <></>
                            )}
                          </div>

                        </div>
                        {
                        factorData.length > 0 ? (
                    <Factor
                      redirectionFactor={redirectionFactor}
                      setRedirectionFactor={setRedirectionFactor}
                      flagUpdated={flagUpdated}
                      setFlagUpdated={setFlagUpdated}
                      scoreCardId={scoreCardId}
                      showValue={showValue}
                      setShowValue={setShowValue}
                      showFactorEdit={showFactorEdit}
                      setShowFactorEdit={setShowFactorEdit}
                      positive={factorData[0].scoreCardpositveFactor}
                      negative={factorData[0].scoreCardnegeitveFactor}
                      userId={userId}
                      data={data}
                      setData={setData}
                    />
                      
                        ) : <></>
                       }
                        
                      </div>
                    </div>
                  </div>
                </div>
                <>
                  <Modal
                    className="custompopup"
                    show={showValue}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    onHide={() => setShowValue(false)}
                  >
                    <Modal.Header closeButton>
                      <h2 className="PopupHeading">
                        {modifyGraphName(graphName)}
                      </h2>
                    </Modal.Header>
                    <Modal.Body>
                      <br />
                      <LineChart data={graph} labels={labels} />
                       {
                        factorData.length > 0 ? (
                    <Factor
                      redirectionFactor={redirectionFactor}
                      setRedirectionFactor={setRedirectionFactor}
                      flagUpdated={flagUpdated}
                      setFlagUpdated={setFlagUpdated}
                      scoreCardId={scoreCardId}
                      showValue={showValue}
                      setShowValue={setShowValue}
                      showFactorEdit={showFactorEdit}
                      setShowFactorEdit={setShowFactorEdit}
                      positive={factorData[0].scoreCardpositveFactor}
                      negative={factorData[0].scoreCardnegeitveFactor}
                      userId={userId}
                      data={data}
                      setData={setData}
                      />
                        ) : <></>
                       }
                    </Modal.Body>
                  </Modal>
                </>
              </div>
            ) : (
              !userId && (
                <img
                  className="no-data-found-txt"
                  src={EmptyDataSign}
                  alt="empty data img"
                />
              )
            )}
          </div>
        )}
      </div>
      {dashboardResult ? (
        <EditEpigenticDetailsModel
          show={show}
          setShow={setShow}
          setFlagUpdated={setFlagUpdated}
          flagUpdated={flagUpdated}
          data={{
            age: dashboardResult ? dashboardResult["epigeneticAge"] : 0,
            ProgressBar,
            userId: userId,
          }}
        />
      ) : (
        <></>
      )}
    </>
  );
};
export default AdminPanel;
