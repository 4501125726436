import axios from "axios";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { ToastOnSuccess } from "../../Toast/ToastMsg";
interface IProps {
  deleteKpiModelShow: boolean;
  setDeleteKpiModelShow: any;
  userId: any;
  activeId: any;
  flagUpdated: any;
  setFlagUpdated: any;
  graphTitle: any;
}
function DelelteKpiField({
  userId,
  activeId,
  flagUpdated,
  setFlagUpdated,
  deleteKpiModelShow,
  setDeleteKpiModelShow,
  graphTitle,
}: IProps) {
  const onDeleteKpiFielidData = () => {
    setDeleteKpiModelShow(false);
    axios
      .delete(
        `https://stage.api.hupe.life/kpi?userId=${userId}&id=${activeId}`,
        {
          headers: {
            Authorization: sessionStorage.getItem("AUTH_TOKEN") || "",
          },
        }
      )
      .then((res) => {
        ToastOnSuccess(res.data.message);
        setFlagUpdated(!flagUpdated);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  return (
    <>
      <Modal
        show={deleteKpiModelShow}
        onHide={() => setDeleteKpiModelShow(false)}
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Do you want to Delete {graphTitle} ?</Modal.Title>
        </Modal.Header>
        <Modal.Body className="ms-auto">
          <Button
            className="m-1"
            variant="secondary"
            onClick={() => setDeleteKpiModelShow(false)}
          >
            Close
          </Button>
          <Button
            className="m-1"
            variant="primary"
            onClick={() => onDeleteKpiFielidData()}
          >
            Delete
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default DelelteKpiField;
