import axios from "axios";
import { useState } from "react";
import { Button, Offcanvas } from "react-bootstrap";
import { capitalizeFirstLetter } from "../../../util/util";
import { ToastOnFailure, ToastOnSuccess } from "../../Toast/ToastMsg";

interface IProps {
  userId?: any;
  colName?:any;
  showEditColumnModel?: any;
  setShowEditColumnModel?: any;
  data?: any;
  flagUpdated:any;
  setFlagUpdated:any;
}

const KpiColumnEdit = ({ userId, showEditColumnModel, setShowEditColumnModel, data, colName,flagUpdated ,setFlagUpdated }: IProps) => {
  let [kpiEditField, setkpiEditField] =useState<any>(data?.map((item:any) => ({kpiId:item.id,value:item.value})));


  const onUpdateEditField = (e:any, id:any) =>{
    let newArr: { value: any; name: string }[] = [];
    kpiEditField.map((item:any)=>{
      if(item.kpiId== id){
        if(e.target.value.match(/^\d*\.?\d*$/))item.value = e.target.value;
      }
      newArr.push(item);
    })
    setkpiEditField(newArr);
  }

  const updateKpiDetails = () => {
    axios
      .patch(
        "https://stage.api.hupe.life/kpi",
        {
          kpiId: data.ItemId,
          userId: userId,
          phase: capitalizeFirstLetter(colName),
          data : kpiEditField.map((item:any)=>({kpiId:item.kpiId, value:parseFloat(item.value)})) 
        },
        {
          headers: {
            Authorization: sessionStorage.getItem("AUTH_TOKEN") || "",
          },
        }
      )
      .then((response: any) => {
        ToastOnSuccess(response.data.message);
        setShowEditColumnModel(false);
        setFlagUpdated(!flagUpdated);
      })
      .catch((error) => {
        console.log(error);
        setShowEditColumnModel(false);
        ToastOnFailure(error.response.data.message);
      });
  };

  return (
    <>
      <Offcanvas
        show={showEditColumnModel}
        onHide={() => setShowEditColumnModel(false)}
        placement="end"
        className="offcanvas-edit-kpi"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Edit {colName=="phase1" ? "Phase 1" : colName=="phase2" ?  "Phase 2" :  colName=="phase3" ? "Phase 3" : null }</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="position-relative">
          <>
            <div className="row">
              <div className="col-6">
              {data?.map((item:any, index:any) => {
                  return (
                    <div
                      className="input-group input-group-sm mb-3 "
                      key={index}
                    >
                      <span
                        className="input-group-text w-100"
                        id="inputGroup-sizing-sm"
                      >
                        {item.kpiName}
                      </span>
                    </div>
                  );
                })}

              </div>
              <div className="col-6">
              {kpiEditField?.map((item:any, index:any) => {
                  return (
                    <div
                      className="input-group input-group-sm mb-3"
                      key={index}
                    >
                      <input
                        type="text"
                        className="form-control"
                        aria-label="Sizing example input"
                        aria-describedby="inputGroup-sizing-sm"
                        name={item.kpiId}
                        value={kpiEditField[index].value}
                        onChange={(e:any)=>onUpdateEditField(e, item.kpiId)}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="fixed-bottom-content">
              <Button
                className="fixed-bottom-content-btn"
                variant="light"
                onClick={() => setShowEditColumnModel(false)}
              >
                Close
              </Button>
              <Button
                className="fixed-bottom-content-btn"
                variant="dark"
                // disabled={}
                onClick={() => updateKpiDetails()}
              >
                Update
              </Button>
            </div>
          </>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default KpiColumnEdit;
